<template>
  <section id="tiss-guides-table">
    <b-table-simple class="table" borderless striped>
      <thead>
        <tr>
          <th v-can="'FatTissLotEnv1'">
            <Check :value="allChecked" @change="!allChecked ? selectAll() : unSelectAll()" />
          </th>
          <th v-for="(header, index) in headers" :key="index">
            <Ellipsis>{{ header }}</Ellipsis>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="hide"></tr>
        <tr v-for="(tissGuide, index) in tissGuides" :key="tissGuide.id">
          <td class="check no-print">
            <Check
              :value="!!selectedTissGuides.includes(tissGuide.id)"
              @change="checked => handleSelectRow(checked, tissGuide)"
            />
          </td>
          <td class="patient">
            {{ tissGuide.patient.name }}<br />
            {{ tissGuide.provider_guide }}
          </td>
          <td class="items">
            <span>{{ normalizeNumber(tissGuide.items_count) }}</span>
            <b-button @click="showItems(tissGuide)" variant="link">
              <Eye class="icon-eye" />
            </b-button>
          </td>
          <td class="situation">
            <SelectItemSituation 
              v-model="tissGuide.situation"
              @change="situation => onChangeSituation(tissGuide, situation)"
            />
          </td>
          <td class="column-default">
            {{ parseDate(tissGuide.updated_at) }}
          </td>
          <td class="column-default">
            {{  parseNumberToMoney(tissGuide.items_total) }}
          </td>
          <td class="receive">
            <span v-if="tissGuide.situation === TissGuide.GUIDE_SITUATION_PARTIALLY_PAID">
              <router-link :to="{ name: 'tiss-manage-guide-items', params: { guideId: tissGuide.id }}">
                <b-button variant="link" class="disabled-value">
                  {{ tissGuide.items_paid > 0 ? parseNumberToMoney(tissGuide.items_paid) : 'Inserir valor' }}
                </b-button>
              </router-link>
            </span>
            <span class="disabled-value" v-else>{{ parseNumberToMoney(tissGuide.items_paid) }}</span>
          </td>
          <td class="column-default">
            <div class="disabled-value" >{{ parseNumberToMoney(tissGuide.items_glossed) }}</div>
          </td>
          <td class="no-print">
            <b-button variant="light" class="edit-observation" @click="showObservationModal(tissGuide, index)">
              <Icon :tooltip="tissGuide.observations">
                <Comment class="icon pointer" :class="{ inactive: !tissGuide.observations }" />
              </Icon>
            </b-button>
          </td>
        </tr>
      </tbody>
    </b-table-simple>

    <div class="selected-guides" v-if="selectedTissGuides.length">
      <p>
        Qtde de guias selecionadas:
        <span>
          <strong>{{ selectedTissGuides.length }}</strong> guia(s)
        </span>
      </p>
      <b-button variant="link" @click="unSelectAll">
        Cancelar seleção
      </b-button>
    </div>
    <NoFilterResults
      v-if="!hasResults"
      message="Os filtros aplicados não geraram nenhum resultado."
    />

    <b-modal 
      id="observation-guide" 
      title="Observações"
      size="lg"
      hide-footer
      centered
      modal-class="modal-custom"
    >
      <div class="row mb-3">
        <div class="col-md-4">
            <label class="label-custom"><strong class="text-blue">Paciente:</strong> {{ modal.patient_name }}</label>
        </div>
        <div class="col-md-4">
            <label class="label-custom"><strong class="text-blue">Guia:</strong> {{ modal.guide_number }} </label>
        </div>
        <div class="col-md-4">
            <label class="label-custom"><strong class="text-blue">Situação:</strong> {{ modal.situation }}</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
            <label class="label-custom"><strong>Observação</strong></label>
        </div>
        <div class="col-md-12">
          <b-form-textarea
            id="observacao"
            v-model="modal.observations"
            placeholder=""
            rows="3"
            max-rows="6"
          ></b-form-textarea>          
        </div>
      </div>

      <div class="row my-3 text-right">
        <div class="col-md-12">
          <b-button class="mt-2 mr-4" variant="outline-danger" @click="hideObservationModal">Cancelar</b-button>
          <b-button class="mt-2" variant="primary" @click="updateObservationModal">Atualizar</b-button>
        </div>
      </div>
    </b-modal>
    
  </section>

</template>
<script>
import { normalizeNumber, onChangeSituation } from '@/modules/tiss/manageGuides/utils/guideHelper'
import { TissGuide, guideStatusOptions } from '@/modules/tiss/manageGuides/utils/statuses'
import { parseNumberToMoney } from '@/utils/moneyHelper'
import '@/assets/scss/modal-custom.scss';

export default {
  name: 'guides-table',
  components: {
    Icon: () => import('@/components/General/Icon'),
    Check: () => import('@/components/General/Check'),
    Comment: () => import('@/assets/icons/Comment-lines.svg'),
    Ellipsis: () => import('@/components/General/Ellipsis.vue'),
    Eye: () => import('@/assets/icons/eye.svg'),
    SelectItemSituation: () => import('@/modules/tiss/manageGuides/components/SelectItemSituation'),
    NoFilterResults: () => import('@/components/General/noFilterResults')
  },
  props: {
    tissGuides: Array,
    selectedTissGuides: Array,
    handleSelectRow: Function,
    selectAll: Function,
    unSelectAll: Function
  },
  data() {
    return {
      TissGuide,
      guideStatusOptions,
      selected: [],
      headers: [
        'Paciente/Nº da guia',
        'Itens',
        'Situação',
        'Atualização',
        'Valor total',
        'A receber',
        'Glosado',
        'Obs'
      ],
      modal: {
        patient_name: '',
        guide_number: '',
        situation: '',
        observations: ''
      }
    }
  },
  computed: {
    allChecked() {
      return this.tissGuides.length > 0 && this.selectedTissGuides.length === this.tissGuides.length
    },
    hasResults() {
      return this?.tissGuides?.length > 0
    },
    getRows() {
      return this.batches && 'rows' in this.batches ? this.batches.rows : null
    }
  },
  methods: {
    normalizeNumber,
    parseNumberToMoney,
    onChangeSituation,
    parseDate(date) {
      return this.moment(date).format('DD/MM/YYYY')
    },
    showItems(tissGuide) {
      this.$router.push({
        name: 'tiss-manage-guide-items',
        params: { guideId: tissGuide.id }
      })
    },
    async showObservationModal(tissGuide, index) {
      const {
        id = '',
        patient: { name = '' } = {},
        provider_guide: guide_number = '',
        situation = '',
        observations = ''
      } = tissGuide || {}; 
    
      this.modal = { id, patient_name: name, guide_number, situation, observations, index };

      this.$bvModal.show('observation-guide');
    },
    hideObservationModal() {
      this.clearObservationModal();
      this.$bvModal.hide('observation-guide');
    },
    updateObservationModal() {
      this.api.updateObservation(this.modal?.id, {
        'observations': this.modal.observations
      }).then(res => {
        this.$toast.success('Atualizado com sucesso!');
        this.tissGuides[this.modal.index].observations = this.modal.observations;
        this.clearObservationModal();
        this.$bvModal.hide('observation-guide');
      });
    },
    clearObservationModal() {
      this.modal = {
        id: null,
        patient_name: '',
        guide_number: '',
        situation: '',
        observations: ''
      };
    }
  }
}
</script>
<style lang="scss" scoped>
#tiss-guides-table {
  .table {
    th {
      vertical-align: middle;
      font-weight: bold;
      color: var(--dark-blue);
    }
    td {
      padding: 0.3rem 0.75rem;
      vertical-align: middle;
    }
    .check {

    }
    .items {
      text-align: left;
    }
    .situation {
      border-left: 1px solid var(--neutral-300);
      border-right: 1px solid var(--neutral-300);
    }
    .receive {
      padding-left: 0;
      .btn.btn-link {
        color: #8696AC;
        text-decoration: underline;
      }
    }
    .column-default {
      
    }
    td:last-of-type {
      width: 80px;
    }
  }
  .icon-eye {
    width: 24px;
    height: 24px;
    fill: var(--blue-500);
  }
  .icon {
    width: 24px;
    height: 24px;
    stroke: var(--blue-500);
    &.inactive {
      stroke: #305BF2;
    }
  }
  .pointer {
    cursor: pointer;
  }
  .arrow {
    fill: var(--blue-500);
  }
  .checkmark {
    width: 25px;
    height: 25px;
  }
  .disabled-value {
    color: var(--type-placeholder);
  }
  .selected-guides {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    right: 0;
    justify-content: space-between;
    padding: 25px 115px 25px;
    font-size: 16px;
    background: white;
    box-shadow: 16px 0 22px -8px rgba(12, 29, 89, 0.2),
      4px 0 4px 0 rgba(12, 29, 89, 0.1);
    p {
      font-size: 16px;
      color: var(--dark-blue);
    }
    span {
      font-size: 16px;
      color: var(--blue-500);
    }
    button {
      font-size: 16px;
      color: #dc3545;
    }
  }
}
</style>
<style lang="scss">
.swal2-input-label {
  padding-left: 30px;
}

#tiss-guides-table {
  .situation {
    .btn-link {
      text-align: left;
    }
  }
}
</style>